@import '~antd/dist/antd.css';

html,
body,
#root {
	min-width: 1600px;
}

@font-face {
	font-family: 'pmzd';
	src: url('./font/pmzd.ttf') format('truetype');
}

.tongji {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	/* background-color: #fff; */
	/* margin-bottom: 16px; */
	padding-top: 20px;
}

.tongji .item {
	width: calc((100% - 100px) / 6);
	height: 100px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	box-sizing: border-box;
	padding: 0 20px;
	background-color: #fff;
	margin-right: 20px;
	margin-bottom: 20px;
}

.tongji .item:nth-child(6n+6) {
	margin-right: 0;
}

.tongji .item .pic {
	width: 50px;
	height: 50px;
	background-color: #5697FF;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 20px;
	font-size: 20px;
	color: #fff;
}

.tongji .item:nth-child(6n+2) .pic {
	background-color: #ff2d3c;
}

.tongji .item:nth-child(6n+3) .pic {
	background-color: #ffa940;
}

.tongji .item:nth-child(6n+4) .pic {
	background-color: #ff7a45;
}

.tongji .item:nth-child(6n+5) .pic {
	background-color: #27b477;
}

.tongji .item:nth-child(6n+6) .pic {
	background-color: #d9d9d9;
}

.tongji .item .info {
	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */
	align-items: flex-start;
}

.tongji .item .info .p2 {
	color: #101010;
	font-size: 16px;
}

.tongji .item .info .p1 {
	color: #101010;
	font-size: 20px;
}

.logopic {
	position: absolute;
	top: 100px;
	left: 65px;
	width: 200px;
}

.loginpost {
	width: calc(100% - 730px);
	height: 100vh;
	/* background-color: #fff; */
	/* padding         : 70px; */
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.loginpost .right {
	width: 520px;
	height: 640px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	/* background-color: rgba(51, 92, 166, .12); */
}

.loginpost .left {
	width: calc(100% - 520px);
	display: flex;
	justify-content: center;
	align-items: center;
}

#normal_login {
	width: 425px;
}

.loginpost .head {
	width: 100%;
	text-align: center;
	margin: 30px 0;
	margin-bottom: 42px;
	font-size: 36px;
	color: #333;
}

.loginpost .input {
	height: 60px;
	font-size: 20px;
}

.loginpost .input .ant-input {
	font-size: 18px;
}

.loginpost .login-form-button {
	width: 100%;
	height: 60px;
	background-color: #3A62D7;
	color: #fff;
	font-size: 16px;
	border: 0
}

.loginpost .footer {
	position: absolute;
	bottom: 70px;
	left: 0;
	right: 0;
	margin: auto;
	width: 100%;
	text-align: center;
}

.bj {
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
}

.bj .l {
	width: 730px;
	height: 100vh;
	background-image: url(./images/loginl.jpg);
	background-size: cover;
	background-position: center;
	position: relative;
	box-sizing: border-box;
	padding-top: 70px;
	padding-left: 60px;
}

.bj .l .logo {}

.simple-verify .verify-bar .icon {
	height: 43px !important;
	background-position: center !important;
	background-repeat: no-repeat !important;
	background-size: auto 100% !important;
}

.header {
	background-color: #3D568E;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
}

.header .logo {
	font-size: 24px;
	color: #fff;
	font-family: 'pmzd';
}

.sellist {
	width: 100%;
	margin-bottom: 16px;
}

.sellist .input,
.sellist .select,
.sellist .but {
	height: 40px;
	font-size: 14px;
}

.blue {
	border: 1px solid rgb(98, 146, 244);
	background-color: rgb(98, 146, 244);
	color: #fff;
}

.yellow {
	border: 1px solid rgb(255, 153, 89);
	background-color: rgb(255, 153, 89);
	color: #fff;
}

.green {
	border: 1px solid rgb(39, 177, 72);
	background-color: rgb(39, 177, 72);
	color: #fff;
}

.red {
	border: 1px solid rgb(255, 37, 37);
	background-color: rgb(255, 37, 37);
	color: #fff;
}

.grey {
	border: 1px solid rgb(150, 150, 150);
	background-color: rgb(150, 150, 150);
	color: #fff;
}

.blue:hover,
.blue:focus {
	border: 1px solid rgba(98, 146, 244, 0.8);
	background-color: rgba(98, 146, 244, 0.8);
	color: #fff;
}

.yellow:hover,
.yellow:focus {
	border: 1px solid rgba(255, 153, 89, 0.8);
	background-color: rgba(255, 153, 89, 0.8);
	color: #fff;
}

.green:hover,
.green:focus {
	border: 1px solid rgba(39, 177, 72, 0.8);
	background-color: rgba(39, 177, 72, 0.8);
	color: #fff;
}

.red:hover,
.red:focus {
	border: 1px solid rgba(255, 37, 37, 0.8);
	background-color: rgba(255, 37, 37, 0.8);
	color: #fff;
}

.grey:hover,
.grey:focus {
	border: 1px solid rgba(150, 150, 150, 0.8);
	background-color: rgba(150, 150, 150, 0.8);
	color: #fff;
}

.funlist {
	width: 100%;
}

.funlist .but {
	width: 100px;
	margin-bottom: 16px;
	font-size: 14px;
}

.table {
	margin-bottom: 25px;
	/* max-height: calc(100% - 150px); */
}

.table .t2 {
	background-color: #f3f3f3;
}

.table .fj {
	color: rgba(39, 177, 72, 100);
}

.page {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 24px;
}

.page.page3 {
	justify-content: space-between;
}

.fgx .ant-divider-inner-text {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.fk {
	width: 7px;
	height: 20px;
	background-color: #3291F8;
	margin-right: 10px;
}

.addview .input,
.addview .select {
	width: 337px;
}

.filelist {
	display: flex;
	justify-content: space-between;
	align-items: center;

}

.filelist .link {
	color: #969696;
}

.filelist .del {
	color: #969696;
	cursor: pointer;
}

.uploadtip {
	color: #20b320;
}

.xiazai {
	font-size: 14px;
	color: #3291F8;
	text-decoration: underline;
}

.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-inline>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
	height: 50px;
	line-height: 50px;
}

.pageheader {
	color: #fff;
}

.delfile {
	color: #f00;
	margin-left: 10px;
	cursor: pointer;
}

.hongse {
	color: #f00;
}

.hongse:hover,
.hongse:focus {
	color: #f00;
}

.huise {
	color: #999;
}

.huise:hover,
.huise:focus {
	color: #999;
}

/* 统计页样式 */
.shaixuan {
	width: 100%;
	margin-bottom: 20px;
}

.tongji1 {
	margin-bottom: 20px;
}

.tongji1 .item {
	width: calc((100% - 50px)/3);
	background-color: #fff;
	box-sizing: border-box;
	padding: 30px 35px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.tongji1 .item .l {
	width: calc(100% - 200px);
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.tongji1 .item .l .pic {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 30px;
	margin-right: 30px;
}

.tongji1 .item .l .pic.p1 {
	background-color: #68BBC4;
}

.tongji1 .item .l .pic.p2 {
	background-color: #5087EC;
}

.tongji1 .item .l .pic.p3 {
	background-color: #F39423;
}

.tongji1 .item .l .info .p1 {
	font-size: 16px;
	color: #aaa;
}

.tongji1 .item .l .info .p2 {
	font-size: 20px;
	color: #333;
	font-weight: bold;
}

.tongji1 .item .r {
	width: 150px;
}


.history_item {
	display: block;
}

.ant-input[disabled] {
	color: rgba(0, 0, 0, 1);
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	color: rgba(0, 0, 0, 1);
}

.ant-picker-input>input[disabled] {
	color: rgba(0, 0, 0, 1);
}


.site-input-group-wrapper .site-input-split {
	background-color: #fff;
}

.site-input-group-wrapper .site-input-right {
	border-left-width: 0;
}

.site-input-group-wrapper .site-input-right:hover,
.site-input-group-wrapper .site-input-right:focus {
	border-left-width: 1px;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right {
	border-right-width: 0;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right:hover,
.site-input-group-wrapper .ant-input-rtl.site-input-right:focus {
	border-right-width: 1px;
}


.ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-tbody>tr>td.ant-table-cell-row-hover {
	background-color: #c8e3f7;
}

.scjhtj .item {
	width: calc((100% - 20px)/2);
	padding: 15px 20px;
	box-sizing: border-box;
	background-color: #fff;
}

.scjhtj .item .p1 {
	margin-bottom: 20px;
}

.scjhtj .item .p2 {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.scjhtj .item .p2 .a .b1 {
	font-size: 16px;
	color: #aaa;
	margin-bottom: 10px;
	position: relative;
}

.scjhtj .item .p2 .a .b1::before {
	content: '';
	position: absolute;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	top: 0;
	bottom: 0;
	margin: auto;
	left: -15px;
}

.scjhtj .item .p2 .a.a1 .b1::before {
	background-color: #84b7f9;
}

.scjhtj .item .p2 .a.a2 .b1::before {
	background-color: #1990ff;
}

.scjhtj .item .p2 .a.a3 .b1::before {
	background-color: #f39423;
}

.scjhtj .item .p2 .a.a4 .b1::before {
	background-color: #f9c78b;
}

.scjhtj .item .p2 .a.a5 .b1::before {
	background-color: #0c70ce;
}

.scjhtj .item .p2 .a .b2 {
	font-size: 20px;
	color: #333;
}

.hvtitle {
	font-size: 16px;
	color: #999;
}

.heititle {
	font-size: 16px;
	color: #333;
	font-weight: bold;
}

.jhtable {
	width: 100%;
	border: 1px solid #ddd;
}

.jhtable td {
	text-align: center;
	color: #333;
	font-size: 14;
	padding: 5px 10px;
}

.jhtable .title1 td {
	background-color: #d5e0ee;
}

.jhtable .title2 td {
	background-color: #eff3fb;
}

.jhtable .title3 td {
	background-color: #f2f3f7;
}

.jhtable .title4 td {
	background-color: #eff7fb;
}

.youhaotj {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	margin-bottom: 15px;
}

.youhaotj .item {
	width: calc((100% - 60px)/5);
	background-color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	box-sizing: border-box;
	padding: 15px;
}

.youhaotj .item .p1 {
	width: calc(100% - 65px);
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: flex-start;
}

.youhaotj .item .p1 .a1 {
	width: 100%;
	font-size: 14px;
	color: #aaa;
}

.youhaotj .item .p1 .a2 {
	width: 100%;
	font-size: 16px;
	color: #333;
}

.youhaotj .item .p1 .a2 .dw {
	font-size: 14px;
	color: #888;
}

.youhaotj .item .p2 {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	font-size: 30px;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.youhaotj .item.i1 .p2 {
	background-color: #5087EC;
}

.youhaotj .item.i2 .p2 {
	background-color: #68BBC4;
}

.youhaotj .item.i3 .p2 {
	background-color: #F39423;
}

.youhaotj .item.i4 .p2 {
	background-color: #FF3D60;
}

.youhaotj .item.i5 .p2 {
	background-color: #5BC4F5;
}


.youhaotj2 {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
}

.youhaotj2 .l {
	width: calc((100% - 60px)/5*2 + 15px);
	background-color: #fff;
	margin-right: 15px;
	box-sizing: border-box;
	padding: 20px;
	overflow-y: auto;
}

.youhaotj2 .r {
	width: calc(100% - ((100% - 60px)/5*2 + 30px));
	background-color: #fff;
	box-sizing: border-box;
	padding: 20px;
}

.ljinfo {
	font-size: 14px;
	color: #888;
}

.ljinfo .c1 {
	color: #20b320;
}

.ljinfo .c2 {
	color: #f00;
}

.weixiutj {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	margin-bottom: 15px;
}

.weixiutj>.item {
	width: calc((100% - 15px)/2);
}

.weixiutj>.item.i1 {
	padding: 15px;
	box-sizing: border-box;
	background-color: #fff;
}

.weixiutj>.item.i1 .title {
	width: 100%;
}

.weixiutj>.item.i1 .content {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.weixiutj>.item.i1 .content .a .b1 {
	font-size: 16px;
	color: #aaa;
	margin-bottom: 10px;
	position: relative;
}

.weixiutj>.item.i1 .content .a .b1::before {
	content: '';
	position: absolute;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	top: 0;
	bottom: 0;
	margin: auto;
	left: -15px;
}

.weixiutj>.item.i1 .content .a.a1 .b1::before {
	background-color: #f39423;
}

.weixiutj>.item.i1 .content .a.a2 .b1::before {
	background-color: #f5a544;
}

.weixiutj>.item.i1 .content .a.a3 .b1::before {
	background-color: #f9c78b;
}

.weixiutj>.item.i1 .content .a .b2 {
	font-size: 20px;
	color: #333;
}

.weixiutj>.item.i2 {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	flex-wrap: wrap;
}

.weixiutj>.item.i2 .a {
	width: calc((100% - 15px)/2);
	margin-right: 15px;
	margin-bottom: 15px;
	box-sizing: border-box;
	padding: 15px;
	background-color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.weixiutj>.item.i2 .a:nth-child(2n+2) {
	margin-right: 0;
}

.weixiutj>.item.i2 .a:nth-last-child(1),
.weixiutj>.item.i2 .a:nth-last-child(2) {
	margin-bottom: 0;
}

.weixiutj>.item.i2 .a .b1 {
	width: calc(100% - 65px);
}

.weixiutj>.item.i2 .a .c1 {
	width: 100%;
	font-size: 16px;
	color: #aaa;
}

.weixiutj>.item.i2 .a .c2 {
	width: 100%;
	font-size: 18px;
	color: #333;
}

.weixiutj>.item.i2 .a .b2 {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	font-size: 26px;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.weixiutj>.item.i2 .a.a1 .b2 {
	background-color: #5087ec;
}

.weixiutj>.item.i2 .a.a2 .b2 {
	background-color: #68bbc4;
}

.weixiutj>.item.i2 .a.a3 .b2 {
	background-color: #f39423;
}

.weixiutj>.item.i2 .a.a4 .b2 {
	background-color: #ff3d60;
}

.nomb {
	margin-bottom: 0;
}

.lrtj {
	width: 100%;
	background-color: #fff;
	margin-bottom: 20px;
	box-sizing: border-box;
	padding: 20px 30px;
}

.lrtj .title {
	width: 100%;
	margin-bottom: 20px;
}

.lrtj .title .p1 {
	font-size: 20px;
	color: #333;
	position: relative;
}

.lrtj .title .p1::before {
	content: '';
	position: absolute;
	height: 100%;
	width: 3px;
	background-color: #4D83FD;
	left: -13px;
	top: 0;
}

.lrtj .tj {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	flex-wrap: wrap;
}

.lrtj .tj .item {
	width: calc((100% - 140px)/8);
	margin-bottom: 20px;
	margin-right: 20px;
}

.lrtj .tj .item:nth-child(8n+8) {
	margin-right: 0;
}

.lrtj .tj .item .p1 {
	width: 100%;
	font-size: 16px;
	color: #aaa;
}

.lrtj .tj .item .p2 {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.lrtj .tj .item .p2 .a1 {
	font-size: 20px;
	color: #333;
	font-weight: bold;
	margin-right: 10px;
}

.lrtj .tj .item .p2 .a2 {
	font-size: 14px;
	color: #888;
}

.lrtj .chart {
	width: 100%;
}

.lrtj .chart .title_total {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 15px;
}

.lrtj .chart .title_total .a1 {
	font-size: 16px;
	color: #aaa;
}

.lrtj .chart .title_total .a2 {
	font-size: 14px;
	color: #333;
}

.lanbj td {
	background-color: #dce6f1;
}

.huangbj td {
	background-color: #f2dcdb;
}

.kqtable .is_ok {
	color: green;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.kqtable .sel {
	position: relative;
	z-index: 1;
}

.focusInput {
	text-align: center;
}
.focusInput[type="text"]::-webkit-inner-spin-button{
	display: none!important;
}